@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  padding: 0;
  margin: 0;

  font-family: Oswald, sans-serif;
}

.container {
  display: flex;
  height: 100vh;
  width: 100vw;
  max-width: 2500px;
  justify-content: center;
}

.left {
  width: 50%;
  background-color: white;
  padding: 25px;

}

.right {
  width: 50%;
  background-color: #F4F4F4;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  padding-bottom: 50px;
}

.topImage {
  margin-top: 15px;
  display: flex;
  justify-content: center;
}

.previewImage {
  width: 178px;
  height: 19%;
  top: 34px;
  left: 171px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.topImage .previewImage:first-child {
  background: #D0D0D0;
}

.topImage .previewImage:nth-child(2) {
  margin-left: 5px;
  background: #EFEFEF;
}

img {
  object-fit: cover;
}

.display {
  display: flex;
  justify-content: center;
  padding-top: 20px;
}

.display img {
  width: 50%;
  object-fit: contain;
}

.desc {
  margin-top: 10px;
  margin-inline: 20px;
  border-top: 1px solid black;
  display: flex;
  justify-content: space-between;
  align-items: center;
}


h3 {
  font-size: 50px;
  line-height: 74.1px;
}

p {
  font-size: 20px;
  line-height: 29.64px;
}

div .align {
  padding-inline: 20px;
}

.align:first-child {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 55px;
  height: 89px;
  /* margin-inline: 20px; */
}

.align h2 {
  font-family: Oswald, sans-serif;
  font-size: 32px;
  font-weight: 400;
  line-height: 74px;
  letter-spacing: 0em;
  text-align: left;
}

.borderContainer {
  /* border-top: 1px solid black; */
  margin-inline: 20px;
}

.colorContent {
  margin-top: 20px;
}

.grid-container {

  display: grid;
  grid-template-columns: repeat(8, 1fr);
  margin-top: 20px;
  column-gap: 30px;
  row-gap: 25px;

}

.grid-item {
  height: auto;
  width: calc((100%\7)-25);
  padding: 20px;
  aspect-ratio: 1;
  text-align: center;

}

.subtext {
  font-family: Oswald, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 37px;
  letter-spacing: 0em;
  text-align: left;

}

.grid-container .grid-item {
  cursor: pointer;

}

.grid-container .grid-item.active {
  border: 2px solid rgb(0, 0, 0);

}

@media (max-width: 767px) {

  .left,
  .right {
    width: 100%;
  }

  .grow {
    margin-top: 50px;
  }

  .borderContainer {
    width: 100%;
    margin-inline: 10px;
  }

  .grid-container {
    display: flex;
    width: 100%;
    overflow: auto;
  }

  .desc {
    margin: 0;
    margin-top: 20px;
  }

  h3 {
    font-size: 27px;
    line-height: 54.1px;
  }

  p {
    font-size: 13px;
    line-height: 20px;
  }

  .align h2 {
    font-size: 20px;
    line-height: 50px;
  }

  #vectorImage {
    max-width: 50%;
  }

  .subtext {
    font-size: 16px;
    line-height: 30px;
  }

  .align:first-child {
    margin-top: 0px;
    height: auto;
    padding: 10px 20px;
  }
}

@media (min-width: 680px) and (max-width: 768px) {
  h3 {
    font-size: 30px;
    line-height: 54.1px;
  }

  p {
    font-size: 15px;
    line-height: 20px;
  }

  .grid-container {
    grid-template-columns: repeat(5, 1fr);
  }

  .align h2 {
    font-size: 26px;
  }

  #vectorImage {
    max-width: 50%;
  }

  .subtext {
    font-size: 17px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {

  .grid-container {
    grid-template-columns: repeat(5, 1fr);
  }

}